export const hiddenFields = ['uuid','winevalue_uuid','code','memo','categories','parent_uuid','model','parent_model','createdby','committed','committedby','modified','modifiedby','deleted','deletedby','ownedby']
export const numberFields = ['number','integer','positivenumber','positiveinteger']
export const timeFields = ['date','datetime','time','timerange']

export const get_html_field_type = (field) => {
    let server_type = field.type,
        server_name = field.name
    if (hiddenFields.includes(server_name)) return 'hidden'
    if (numberFields.includes(server_type)) return 'number'
    if (server_type==='boolean') return 'checkbox'
    if (server_type==='datetime') return 'datetime-local'
    if (server_type==='time'||server_type==='timerange') return 'time'
    if (server_type==='date') return 'date'
    return 'text' 
}

export const get_field_value = (field,objectData) => {
    if (field.name==='price' && !isNaN(objectData[field.name]) && objectData[field.name]!==''){
        return(Math.round(objectData[field.name]*100)/100)
    }
    if (timeFields.includes(field.type)){
        if (!isNaN(objectData[field.name]) && objectData[field.name]!==null) {
            let o = new Date(objectData[field.name]);
            return o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()+'T'+(o.getHours()<10?'0':'')+o.getHours()+':'+(o.getMinutes()<10?'0':'')+o.getMinutes();
        }
        return null
    }
    if(numberFields.includes(field.type)){
        if(isNaN(objectData[field.name]) || objectData[field.name]===null){
            return ''
        }
    }
    return objectData[field.name]
}

export const get_post_object_field_value = (field,value) => {
    if (field.type==='datetime'){
        if (value===null) return null;
        let o = new Date(value)
        return o.getTime()
    }
    if (field.type==='boolean'){
        return (value==='true')?false:true
    }
    if (field.type==='number'){
        if (!isNaN(value)){
            return parseFloat(value)
        } else {
            return null
        }
    }
    if(field.type==='integer'){
        if (!isNaN(value)){
          return Math.round(parseFloat(value))
        } else {
          return null
        }
    }
    return value
}

export const check_field_validation = (structureData,objectData) => {
    let validated=[]
    for(let k in structureData){
      // List validation rules
      let required = structureData[k].required,
          min = structureData[k].min,
          max = structureData[k].max,
          // Check value against validation rules
          value = objectData[structureData[k].name],
          type=structureData[k].type
      if((min && value<min) || (max && value > max) || (required && (value==='' || value === null || typeof value === 'undefined'))){
          validated.push({'name':structureData[k].name,'valid':false})
      } else if (type==='uuid' && (value==='' || value===null || typeof value === 'undefined')) {
        //validated.push({'name':structureData[k].name,'valid':false})
      } else {
        validated.push({'name':structureData[k].name,'valid':true})
      }          
    }
    return validated
}

export const get_field_isvalid = (field,validation) => {
    for(let k in validation){
        if (validation[k].name === field.name && validation[k].valid===false){
            return false
        }
    }
    return true
}

const convertHtmlFieldDateToTimeStamp = (dateString) => {
    let o = new Date(dateString)
    if(o) return o.getTime()
    return null
}

const restore_backend_structure = (dataStructure) => {
    let aut = {}
    for (let k in dataStructure){
        aut[dataStructure[k].name]=dataStructure[k]
    }
    return aut
}

const get_backend_filter_value = (filterModelRow,dataStructure,name) => {
    let fields = restore_backend_structure(dataStructure.structure)
    if(numberFields.includes(fields[name].type) && filterModelRow['filter']) return parseFloat(filterModelRow['filter'])
    //if (filterModelRow['type']==='number') return parseFloat()
    if(filterModelRow['filter'])return filterModelRow['filter']
    if(filterModelRow['dateFrom'])return convertHtmlFieldDateToTimeStamp(filterModelRow['dateFrom'])
    if (filterModelRow['type']==='true')return true
    if (filterModelRow['type']==='false')return false
}

const get_backend_filter_function = (gridfunction) => {
    if(gridfunction==='contains_b')return 'contains'
    //if(gridfunction==='equals')return 'eq'
    //if(gridfunction==='greaterThan')return 'gt'
    //if(gridfunction==='lessThan')return 'lt'
    //if(gridfunction==='greaterThanOrEqual')return 'gte'
    //if(gridfunction==='greaterThanOrEqual')return 'lte'
    if(gridfunction==='true'||gridfunction==='false')return 'is'
    return gridfunction
}

export const get_backend_sort_model = (columnState) => {
    let sortBy={
        sortColumn: null,
        sortOrder: null
    }
    for(let k in columnState){
        if(columnState[k]['sort']==='asc'||columnState[k]['sort']==='desc'){
            sortBy = {
                sortColumn: columnState[k]['colId'],
                sortOrder: columnState[k]['sort']
            }
        }
    }
    return sortBy
}

export const get_backend_filter = (filterModel,sortModel,freeSearchString,dataStructure) => {
    
    let fields = []
    if(filterModel){
        for (let k in filterModel){
            //console.log('K: '+k)
            // Se filtro range, costruire i between con due campi gte e lte per lo stesso field
            if(filterModel[k]['type']==='inRange_b'){
                /* for (let j in filterModel[k]){
                    console.log('J: '+j+', VAL:'+filterModel[k][j])
                } */
                let filterRowFrom = {
                    field: k,
                    value: filterModel[k]['dateFrom']?convertHtmlFieldDateToTimeStamp(filterModel[k]['dateFrom']):filterModel[k]['filter'],
                    func: 'gte'
                }
                let filterRowTo = {
                    field: k,
                    value: filterModel[k]['dateTo']?convertHtmlFieldDateToTimeStamp(filterModel[k]['dateTo']):filterModel[k]['filterTo'],
                    func: 'lte'
                }
                fields.push(filterRowFrom)
                fields.push(filterRowTo)
            } else {
            // Se filtro normale, costruire il normale campo a filtro singolo
                let filterRow = {
                    field: k,
                    value: get_backend_filter_value(filterModel[k],dataStructure,k),
                    func: get_backend_filter_function(filterModel[k]['type'])
                }
                fields.push(filterRow)
            }
        }
        
    }
    let sort = {}
    if (sortModel && sortModel.sortColumn && sortModel.sortOrder){
        sort={"field": sortModel.sortColumn, "desc": sortModel.sortOrder === 'desc'?true:false}
    }
    let bkf = {
        "query": freeSearchString,
        "format":"ui",
        "inputformat": "ui",
        "sort": sort,
        "offset": 0,
    }
    if(fields && fields.length){
        bkf.filter={
            "func": "and",
            "fields": fields
        }
    }
    return bkf
}

export const get_autocomplete_request_body = (searchString) => {
    return {
        "query": searchString,
        "format": "autocomplete",
        "sort": {
            "field": "memo"
        }
    }
}

export const get_autocomplete_onload_body = (value) => {
    return {
        "filter":{
            "func": "and",
            "fields": [
                {
                    "field": "uuid",
                    "value": value,
                    "func": "eq"
                }
            ]
        },
        "format": "autocomplete",
        "offset": 0,
        "count": false
    }
}

export const get_html_field_classname = (field,creating) => {
    if (get_html_field_type(field)==='datetime-local' && (field.name==='deleted')){
        return 'd-none' //'form-control'
    }
    if (get_html_field_type(field)==='datetime-local' && (field.name==='created' && creating)){
        return 'd-none' //'form-control'
    }
    if (get_html_field_type(field)==='checkbox'){
        return 'form-check-input mcr-checkbox d-block'
    }
    return 'form-control'
}

export const get_html_fieldlabel_classname = (field,creating) => {
    if (get_html_field_type(field,creating)==='hidden'){
        return 'd-none'
    }
    if (get_html_field_classname(field,creating) === 'd-none'){
        return 'd-none'
    }
    if(field.name==='uuid'){ // May be not hidden for index table use
        return 'd-none'
    }
    return 'form-label text-even mt-3'
}

export const get_checked = (field,objectData) => {
    let type=get_html_field_type(field),
        value=get_field_value(field,objectData)
    if(type==='checkbox'&&value===true)return true;
    return false;
}

export const convertTimestampToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}

export const convertDateOnlyToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleString('it-IT', options);
}

export const convertTimeOnlyToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}