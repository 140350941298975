import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get_html_field_type, get_field_value, get_checked, get_html_field_classname, get_html_fieldlabel_classname, get_post_object_field_value, get_field_isvalid, check_field_validation } from '../helper'
import AutocompleteInput from '../fields/Autocomplete'

const Editform = (props) => {

  const {nomeSing,url,apiModel,enableSave,enableDelete} = props

  const [loading1, setloading1] = useState(false)
  const [saved, setSaved] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [loadErr, setLoadErr] = useState(null)
  const [saveErr, setSaveErr] = useState(null)
  const [deleteErr, setDeleteErr] = useState(null)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [delBtnDisabled, setDelBtnDisabled] = useState(false)
  const [objectData, setObjectData] = useState({})
  const [modifiedFields, setModifiedFields] = useState({})
  const [committed,setCommitted] = useState(false)
  const [validation, setValidation] = useState([])
  const [rendered, setRendered] = useState(false)
  const { id } = useParams()

  const structureData = useSelector((state)=> state.dataStructure.structure)

  const navigate = useNavigate()

  useEffect(()=>{setRendered(true)},[])

  useEffect(()=>{
    setValidation([])
    let validated=check_field_validation(structureData,objectData)
    setValidation(validated)
  },[structureData,objectData])

  useEffect(() => {
    setloading1(true)
    setLoadErr(null)
    const getData = async () => {
      const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/load'
      const config = {
        headers: { 'Authorization': '12345' },
        params: {}
      }
      let data = [id]
      try {
        const response = await axios.post(url, data, config)
        const recdata = response.data.result[id]
        if (recdata) {
          if(recdata.deleted){
            setDeleted(true)
          }else{
            setDeleted(false)
          }
          if(recdata.committed){
            setCommitted(true)
          }else{
            setCommitted(false)
          }
          setObjectData(recdata)
        }
        setloading1(false)
      } catch (error) {
        if(error.response.data.error.code && error.response.data.error.description){
          setLoadErr('Errore '+error.response.data.error.code+': '+error.response.data.error.description)
        } else {
          setLoadErr('Errore di rete al caricamento!')
        }
        setloading1(false)
      }
    }
    getData()
  }, [setloading1,id,apiModel]
  )

  const handleChange = (value, field) => {
    let objectData2 = objectData
    objectData2[field.name] = get_post_object_field_value(field, value)
    let modifiedFields2 = modifiedFields
    modifiedFields2[field.name] = objectData2[field.name]
    if(!committed){
      objectData2['committed']=1
      modifiedFields2['committed']=1
      setCommitted(true)
    }
    setObjectData({ ...objectData2 })
    setModifiedFields({ ...modifiedFields2 })
  }

  const handleSave = () => {
    for (let k in validation) {
      if (!validation[k].valid) {
        return alert('Alcuni campi non sono validi')
      }
    }
    setSaved(false)
    setSaveErr(null)
    setBtnDisabled(true)
    const saveNow = async () => {
      const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/save'
      const config = {
        headers: { 'Authorization': '12345' },
      }
      let data = modifiedFields
            data.uuid=objectData.uuid
            const postData = [data]
      try {
        const response = await axios.post(url, postData, config)
        if (response.data.result === true) {
          setSaved(true)
          setTimeout(() => {
            setSaved(false)
            setBtnDisabled(false)
          }, 2000)
        } else {
          setSaveErr(response.data.error)
          setTimeout(() => {
            setSaveErr(null)
            setBtnDisabled(false)
          }, 2000)
        }
        setModifiedFields({})
      } catch (error) {
        if(error.response.data.error.code && error.response.data.error.description){
          setSaveErr('Errore '+error.response.data.error.code+': '+error.response.data.error.description)
        } else {
          setSaveErr('Errore di rete al salvataggio!')
        }
        setTimeout(() => {
          setSaveErr(null)
          setBtnDisabled(false)
        }, 2000)
      }
    }
    saveNow()
  }

  const handleDelete = () => {
    setDeleted(false)
    setDeleteErr(null)
    setDelBtnDisabled(true)
    const deleteNow = async () => {
      const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/save'
      const config = {
        headers: { 'Authorization': '12345' },
      }
      const data = {'deleted': 1, 'uuid': id}
      const postData = [data]
      try {
        const response = await axios.post(url, postData, config)
        if (response.data.result === true) {
          setDeleted(true)
        } else {
          setDeleteErr(response.data.error)
          setTimeout(() => {
            setDeleteErr(null)
            setDelBtnDisabled(false)
          }, 2000)
        }
      } catch (error) {
        if(error.response.data.error.code && error.response.data.error.description){
          setSaveErr('Errore '+error.response.data.error.code+': '+error.response.data.error.description)
        } else {
          setSaveErr('Errore di rete alla cancellazione!')
        }
        setTimeout(() => {
          setDeleteErr(null)
          setDelBtnDisabled(false)
        }, 2000)
      }
    }
    deleteNow()
  }

  const handleBack = () => {
    setRendered(false)
    setTimeout(
      ()=>{navigate(process.env.REACT_APP_LOCAL_SUBFOLDER + url)},500
    )
  }

  return (
    <>
    <div className={"overlay"+(rendered ? ' visible' : '')}>
      <div className="container-fluid px-0">

          <div className='w-100 bg-dark form-header pt-4 pb-3'>
            <div className="row">
              <div className="col-12 col-md-2 col-lg-3 col-xl-4">
                <button className='btn mcr-btn btn-with-icon ms-4 ms-md-2 mb-2' onClick={() => {handleBack()}}><span className="material-symbols-rounded header-icon">arrow_back</span> Indietro</button>
              </div>
              <div className="col-12 col-md-8 col-lg-6 col-xl-4 pb-2">
                <h3 className='text-even ms-4 ms-md-0'>Vista {nomeSing}</h3>
              </div>
            </div>
          </div>

        {loading1 && <h3 className='h3 text-even mt-5 pt-5 ms-3'>Caricamento...</h3>}
        {!loading1 && loadErr && <h3 className='h3 text-even mt-5 pt-5 ms-3'>{loadErr}</h3>}
        {!loading1 && objectData && structureData && <div className='w-100 ps-4 ps-md-2 pe-2'>

        {!loadErr && enableDelete && <div className="row" style={{marginTop: '120px'}}>
            <div className="col-12 text-end">
              <button className='btn mcr-btn danger btn-with-icon ms-0' onClick={() => { handleDelete() }} disabled={delBtnDisabled||deleted}><span className="material-symbols-rounded header-icon">delete</span> Cancella</button>
            </div>
          </div>}

          <div className='row' style={enableDelete?{}:{marginTop: '120px'}}>
              {
                !loadErr && structureData.length && structureData.map((field, idx) => {
                  return <React.Fragment key={idx}>
                    <div className={"col-12 col-md-6 "+(get_html_fieldlabel_classname(field)==='d-none'?'d-none':'')}>
                      <label
                        className={get_html_fieldlabel_classname(field)}>
                        <b>
                          {(field.label ? field.label.charAt(0).toUpperCase()+field.label.slice(1) : field.name.charAt(0).toUpperCase()+field.name.slice(1)) + (field.name === 'price' ? ' €' : '')}
                        </b>
                      </label>
                    {field.type !== 'uuid' && <input 
                      disabled={deleted}
                      readOnly={field.name==='created'} 
                      name={field.name}
                      required={field.required}
                      className={get_html_field_classname(field) + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      type={get_html_field_type(field)}
                      value={get_field_value(field, objectData) ?? ''}
                      min={get_html_field_type(field)==='number'?field.min:null}
                      max={get_html_field_type(field)==='number'?field.max:null}
                      checked={get_checked(field, objectData)}
                      onChange={(e) => { handleChange(e.target.value, field) }} 
                    />}
                    {field.type === 'uuid' && field.name !== 'uuid' && <AutocompleteInput
                      makeDisable={deleted}
                      apiSubModel={field.name}
                      value={get_field_value(field, objectData) ?? ''}
                      autoCompleteClass={get_html_field_classname(field) + (' material-form-control' + (!get_field_isvalid(field,validation) ? ' is-invalid' : '') + (deleted?' custom-disabled':''))}
                      field={field}
                      changeHandler={handleChange}
                    />}
                    </div>
                    </React.Fragment>
                })}
          </div>

          {!loadErr && enableSave && <div className="row">
            <div className="col-12 text-end mt-5">
              <button className='btn mcr-btn btn-with-icon ms-0 mb-2' onClick={() => { handleSave() }} disabled={btnDisabled||deleted}><span className="material-symbols-rounded header-icon">save</span> Salva</button>
            </div>
          </div>}

          {saved && <div className="row">
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-primary'><b>Salvato con successo!</b></p></div>
          </div>}
          {saveErr && <div className="row">
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-danger'><b>{saveErr}</b></p></div>
          </div>}
          {deleted && <div className="row">
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-primary'><b>Questo oggetto è cancellato</b></p></div>
          </div>}
          {deleteErr && <div className="row">
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-danger'><b>{deleteErr}</b></p></div>
          </div>}

        </div>}
      </div>
      </div>
    </>
  )
}

export default Editform
