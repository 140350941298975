import { configureStore, createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
      logged_in: false,
      wrong_login: false,
      hash: ''
  },
  reducers: {
    setWrongLogin: (state,action) => {
      state.wrong_login = action.payload
      localStorage.setItem('wrong_login',state.wrong_login)
    },
    logIn: (state,action) => {
      state.logged_in=true
      state.wrong_login=false
      state.hash=action.payload.hash
    },
    saveLoginInLocalStorage: state => {
      localStorage.setItem('logged_id',state.logged_in?'true':'false')
      localStorage.setItem('wrong_login',state.wrong_login?'true':'false')
      localStorage.setItem('hash',state.hash)
    },
    loadLoginFromLocalStorage: state => {
      state.logged_in=localStorage.getItem('logged_id')==='true'?true:false
      state.wrong_login=localStorage.getItem('wrong_login')==='true'?true:false
      state.hash=localStorage.getItem('hash')
    },
    logOut: state => {
      state.logged_in=false
      state.hash=''
      state.badge_number=''
      localStorage.setItem('logged_id','false')
      localStorage.setItem('hash','')
    }
  }
})

const tableDataSlice = createSlice({
  name: 'data',
  initialState: {tableData: []},
  reducers: {
    setData: (state,action) => {
      state.tableData=action.payload
    }
  }
})
const dataStructureSlice = createSlice({
  name: 'dataStructure',
  initialState: {structure: {}},
  reducers: {
    setDataStructure: (state,action) => {
      state.structure=action.payload
    }
  }
})
const dataLoadedCountSlice = createSlice({
  name: 'loadedCount',
  initialState: {
    lodadedCount: 0,
    triggerReload: 0
  },
  reducers: {
    setLoadedCount: (state,action) => {
      state.lodadedCount=action.payload
    },
    setTriggerReload: (state,action) => {
      state.triggerReload=action.payload
    }
  }
})

const menuSlice = createSlice({
  name: 'menu',
  initialState: {show: false},
  reducers: {
    setShow: (state,action) => {
      state.show=action.payload
    }
  }
})

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    data: tableDataSlice.reducer,
    dataStructure: dataStructureSlice.reducer,
    menu: menuSlice.reducer,
    dataLoadedCount: dataLoadedCountSlice.reducer
  },
});

export const {logIn,saveLoginInLocalStorage,loadLoginFromLocalStorage,logOut,setWrongLogin} = userSlice.actions
export const {setData} = tableDataSlice.actions
export const {setShow} = menuSlice.actions
export const {setDataStructure} = dataStructureSlice.actions
export const {setLoadedCount,setTriggerReload} = dataLoadedCountSlice.actions

export default store;
