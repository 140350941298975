import React, {useState, useCallback, useMemo, useEffect} from 'react'
import { AgGridReact } from 'ag-grid-react' // AG Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the grid
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../app/store'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { convertTimestampToReadable, convertDateOnlyToReadable, convertTimeOnlyToReadable, get_backend_filter, get_backend_sort_model } from '../helper'
import { hiddenFields, numberFields, timeFields } from '../helper'

const TableComponent = (props) => {

    const {nomeSing,url,apiModel,triggerReset,freeSearch} = props
    const [fs,setFontSize] = useState(localStorage.getItem(nomeSing+'_fontSize')?localStorage.getItem(nomeSing+'_fontSize'):'16px')
    const [gridApi, setGridApi] = useState(null)
    const [loadedCount,setLoadedCount] = useState(0)
    const parentLoadCount = useSelector((state)=> state.loadedCount)
    const navigate = useNavigate()

    // Reset filters and columns if parent component command so by increasing triggerReset variable
    // No need to call axios data get method since it's done in parent component
    useEffect(()=>{
        if(triggerReset){
            if(gridApi) {
                gridApi.setFilterModel({})
                localStorage.setItem(nomeSing+'_initialState','{}')
                const originalColState = JSON.parse(localStorage.getItem(nomeSing+'_originalColumnState'))
                if(originalColState){
                    gridApi.applyColumnState({state: originalColState,applyOrder: true,})
                }
                localStorage.setItem(nomeSing+'_columnState','[]')
            }
        }
    },[triggerReset,gridApi,nomeSing])

    // Resets scroll if data is loaded in table (e.g. because a filter is changed) but not on first loading
    useEffect(()=>{
        if(parentLoadCount && parentLoadCount > 2){
            if(gridApi){
                setTimeout(()=>{
                    gridApi.paginationGoToPage(0)
                    gridApi.ensureIndexVisible(0, "top")
                    localStorage.setItem(nomeSing+'_scrollIndex', 0)
                    localStorage.setItem(nomeSing+'_initialState', JSON.stringify(gridApi.getState()))
                },50)
            }
        }
    },[gridApi,parentLoadCount,nomeSing])

    // Restore previous page displayed and scroll on first loading only
    useEffect(() => {
        
        if (gridApi) {
            const inState = JSON.parse(localStorage.getItem(nomeSing+'_initialState'))
            const scrollIndex = parseInt(localStorage.getItem(nomeSing+'_scrollIndex'))?parseInt(localStorage.getItem(nomeSing+'_scrollIndex')):0
            setTimeout(()=>{
                if(inState && inState.pagination && inState.pagination.page){
                    gridApi.paginationGoToPage(inState.pagination.page)
                }
                gridApi.ensureIndexVisible(scrollIndex, "top")}
            ,200)
        }
    }, [gridApi,nomeSing]);

    const customValueFormatter = (fieldname,fieldtype,p) => {
        if (fieldname==='price' && !isNaN(p.value)) {
            return '€ ' + (Math.round(p.value*100)/100).toLocaleString()
        }
        if (timeFields.includes(fieldtype) && !isNaN(p.value)) {
            if(fieldtype==='datetime'){
                return convertTimestampToReadable(p.value)
            }
            if(fieldtype==='date'){
                return convertDateOnlyToReadable(p.value)
            }
            if(fieldtype==='time'||fieldtype==='timerange'){
                return convertTimeOnlyToReadable(p.value)
            }
        }
        return p.value
    }

    const handleSizeUp = () => {
        let curFs=fs.split('px')[0];
        let curNum=parseInt(curFs);
        if(!isNaN(curNum)){
            let newNum=curNum+2;
            setFontSize(newNum+'px');
            localStorage.setItem(nomeSing+'_fontSize',newNum+'px')
        }
    }
    const handleSizeDown = () => {
        let curFs=fs.split('px')[0];
        let curNum=parseInt(curFs);
        if(!isNaN(curNum) && curNum>=8){
            let newNum=curNum-2;
            setFontSize(newNum+'px');
            localStorage.setItem(nomeSing+'_fontSize',newNum+'px')
        }
    }

    const getFilterType = (fieldtype) => {
        if (timeFields.includes(fieldtype)) return 'agDateColumnFilter'
        return true
    } 

    const getFilterParams = (fieldtype) => {
        let filterParams = {}
        if (numberFields.includes(fieldtype) || timeFields.includes(fieldtype)) {
            filterParams.filterOptions = [
                {
                    displayKey: 'eq',
                    displayName: 'Uguale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'gt',
                    displayName: 'Maggiore di',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'gte',
                    displayName: 'Maggiore o uguale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'lt',
                    displayName: 'Minore di',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'lte',
                    displayName: 'Minore o uguale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'inRange_b',
                    displayName: 'Nell\'intervallo',
                    predicate: ([fv1, fv2], cellValue) => true,
                    numberOfInputs: 2
                },
            ]
        } else if(fieldtype !== 'boolean') {
            filterParams.filterOptions = [
                {
                    displayKey: 'contains_b',
                    displayName: 'Contiene',
                    predicate: ([filterValue], cellValue) => true
                }
            ]
        }
        filterParams.debounceMs = 200
        filterParams.maxNumConditions = 1
        return filterParams
    }

    // data: The data to be displayed.
    const data = useSelector((state)=> state.data)
    // dataStructure: The model fields structure
    const dataStructure = useSelector((state)=> state.dataStructure)
    const dispatch = useDispatch()

    // Column Definitions: Defines the columns to be displayed.
    const precolDefs=[];
    for (const key in dataStructure.structure) {
        let fieldname = dataStructure.structure[key].name
        let fieldlabel = dataStructure.structure[key].label
        let fieldtype = dataStructure.structure[key].type
        precolDefs.push({
            field: fieldname,
            headerName: fieldlabel?fieldlabel.charAt(0).toUpperCase() + fieldlabel.slice(1):fieldname.charAt(0).toUpperCase() + fieldname.slice(1),
            hide: (hiddenFields.includes(fieldname)) ? true : false,
            cellClass: (numberFields.includes(fieldtype)) ? 'ag-right-aligned-cell' : '',
            valueFormatter: p => customValueFormatter(fieldname,fieldtype,p),
            filter: getFilterType(fieldtype),
            filterParams: getFilterParams(fieldtype)
        });
    }
    const [colDefs] = useState(precolDefs)

    const onGridReady = (params) => {
        setGridApi(params.api)
        // Save original column state to be able to restore it when user resets table
        if(!localStorage.getItem(nomeSing+'_columnState')){
            localStorage.setItem(nomeSing+'_originalColumnState', JSON.stringify(params.api.getColumnState()))
        }
    }

    // If needed, this event is available
    const onFirstDataRendered = (params) => {
    }

    const handleRowClicked = (event) => {
        const clickedId = event.data.uuid
        const editurl = process.env.REACT_APP_LOCAL_SUBFOLDER+url+'/'+clickedId
        navigate(editurl)
    }

    const onColumnMovedOrResized = (event) => {
        const columnState = JSON.stringify(event.api.getColumnState())
        localStorage.setItem(nomeSing+'_columnState', columnState)
        localStorage.setItem(nomeSing+'_initialState',JSON.stringify(event.api.getState()))
    }

    const handleFilterChanged = (event) => {
        // Ottieni i valori dei filtri
        const filterModel = event.api.getFilterModel()
        // Salva il filtro in local storage (è contenuto nello stato)
        localStorage.setItem(nomeSing+'_initialState',JSON.stringify(event.api.getState()))
        // Ottieni l'eventuale ordinamento per colonna
        const columnState = event.api.getColumnState()
        const sortBy=get_backend_sort_model(columnState)
        setLoadedCount(loadedCount+1)
        getFilteredData(filterModel,sortBy,gridApi,freeSearch)  
    }

    const handleScroll = (event) => {
        const scrollPosTop = event.top
            const pageRowIndex = (Math.abs(Math.round(scrollPosTop/42)));
            let firstPageRow = pageRowIndex>10?event.api.getFirstDisplayedRowIndex()-pageRowIndex+10:event.api.getFirstDisplayedRowIndex()
            if (firstPageRow%5!==0){
                firstPageRow+=1;
            }
            const scrollIndex = firstPageRow+pageRowIndex
            localStorage.setItem(nomeSing+'_scrollIndex', scrollIndex)
            localStorage.setItem(nomeSing+'_initialState', JSON.stringify(event.api.getState()))
    }

    const handlePaginationChanged = (event) => {
        localStorage.setItem(nomeSing+'_initialState', JSON.stringify(event.api.getState()))
    }

    const getFilteredData = async(filterModel,sortModel,gridApi,freeSearchString) => {
        const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/search'
        const config = {
            headers: {'Authorization': '12345'},
            params: {}
        }
        let requestBody=get_backend_filter(filterModel,sortModel,freeSearchString,dataStructure)
        try{
            const response = await axios.post(url,requestBody,config)
            const recdata = response.data.result
            if(typeof recdata === 'object'){
                dispatch(setData(recdata))
                if(loadedCount>0){
                    if(gridApi){
                        setTimeout(()=>{
                            gridApi.paginationGoToPage(0)
                            gridApi.ensureIndexVisible(0, "top")
                            localStorage.setItem(nomeSing+'_scrollIndex', 0)
                            localStorage.setItem(nomeSing+'_initialState', JSON.stringify(gridApi.getState()))
                        },50)
                    }
                }
            }
        } catch(error){
            console.log(error)
        }
    }

    const getInitialState = () => {
        let go = {}
        const inState = JSON.parse(localStorage.getItem(nomeSing+'_initialState'))
        if(inState){
            go=inState
        }
        return go
    }
  
    return (
        // wrapping container with theme & size
        <>
        <div
         className="ag-theme-quartz-dark ag-grid-table" // applying the grid theme
         style={{fontSize: fs}} // the grid will fill the width of the parent container
        >
          <AgGridReact
              rowData={data.tableData}
              initialState={getInitialState()}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onColumnMoved={onColumnMovedOrResized}
              onColumnResized={onColumnMovedOrResized}
              onRowClicked={handleRowClicked}
              onFilterChanged={handleFilterChanged}
              onSortChanged={handleFilterChanged}
              onBodyScrollEnd={handleScroll}
              onPaginationChanged={handlePaginationChanged}
              getRowId={(params) => {
                return params.data.uuid;
              }}
              pagination={false}
              paginationPageSizeSelector={useMemo(() => {
                return [50,100,200,500,1000];
              }, [])}
              paginationNumberFormatter={useCallback((params) => {
                return params.value.toLocaleString();
              }, [])}
          />
        </div>
        <div className='row mt-1'>
            <div className='col-12 text-start'>
                <span className='label text-even'>Dimensione carattere: </span>
                <button type="button" className='btn btn-sm mcr-btn' style={{padding: '4px 12px'}} onClick={handleSizeDown}> - </button>
                <button type="button" className='btn btn-sm mcr-btn ms-1' style={{padding: '4px 8px'}} onClick={handleSizeUp}>+</button>
            </div>
        </div>
        </>
      )
  
  }

export default TableComponent;
