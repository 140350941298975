import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { loadLoginFromLocalStorage } from "./app/store";
import Home from './templates/Home';
import Redirector from './templates/Redirector';
import { Fragment } from 'react';

const App = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(loadLoginFromLocalStorage())
  });

  const page_config = [
    {nome_link:"Homepage",nome_icona:"home",url:'/home',apiModel:'product',nomeSing:"prodotto",nomePlur:"prodotti",enableSave:true,enableDelete:true},
    /* {nome_link:"Errori",nome_icona:"warning",url:'/errors',apiModel:'product',nomeSing:"errore",nomePlur:"errori",enableSave:false,enableDelete:false}, */
    {nome_link:"API Reference",nome_icona:"summarize",url:'/apihelp',apiModel:'product',nomeSing:"api",nomePlur:"api",enableSave:false,enableDelete:false}
  ]
  
  /* Specific routing done in Centralbody */
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirector/>} />
        {
          page_config.map((page,index)=>(<Fragment key={index}>
            <Route path={page.url} element={
              <Home url={page.url} apiModel={page.apiModel} nomeSing={page.nomeSing} nomePlur={page.nomePlur} enableSave={page.enableSave} enableDelete={page.enableDelete} links={page_config} />
            } />
            <Route path={page.url+'/:id'} element={
              <Home url={page.url} apiModel={page.apiModel} nomeSing={page.nomeSing} nomePlur={page.nomePlur} enableSave={page.enableSave} enableDelete={page.enableDelete} links={page_config} />
            } />
          </Fragment>
          ))
        }
      </Routes>
    </BrowserRouter>
  );
};

export default App;
